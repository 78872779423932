import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  return (
    <header className="header border-b-[1px] border-gray-50 py-2 px-6 lg:px-10 sticky top-0 bg-[rgba(255,255,255,0.8)] z-[888]">
      <div className="flex justify-between items-center max-w-[1440px] mx-auto">
        <NavLink to="/" className="">
          <img
            src="/icons/ATC_Africa_logo_(Purple).svg"
            alt="logo"
            className="w-[110px] h-[50px] md:w-[140px] md:h-[70px]"
          />
        </NavLink>

        {/* Mobile Navigation */}
        <div className="lg:hidden">
          <button
            onClick={() => {
              setIsNavOpen(!isNavOpen);
            }}
          >
            <img
              src="/icons/hamburger-menu.svg"
              alt="menu"
              className="size-[36px]"
            />
          </button>
        </div>
        {isNavOpen && (
          <div className="lg:hidden fixed bg-white  min-h-[100vh] w-[300px] top-0 right-0 flex flex-col py-[40px]  items-center gap-8 shadow-sm">
            <button
              onClick={() => {
                setIsNavOpen(!isNavOpen);
              }}
            >
              <img
                src="/icons/close-menu.svg"
                alt=""
                className="cursor-pointer absolute left-4 top-4 size-[30px] bg-gray-200 shadow-sm rounded-[50px] p-1"
              />
            </button>
            <nav className="">
              <ul className="flex flex-col items-center gap-14">
                <li>
                  <NavLink className="hover:bg-hoverColor py-1 px-2" to="/">
                    Home
                  </NavLink>
                </li>
                <li className="about-wrapper relative hover:bg-hoverColor">
                  <p className="flex justify-center items-center gap-2 about hover:cursor-pointer py-1 px-2">
                    <span className="about-text">About ATC</span>
                    <img
                      src="/icons/arrow-up.svg"
                      alt=""
                      className="about-arrow"
                    />
                  </p>
                  <div className="drop-down absolute top-2 w-max -left-[25px]">
                    <ul className="flex flex-col gap-2 mt-8 shadow-[0_0_5px_-0px_rgba(0,0,0,0.3)] rounded-[8px] w-[170px] px-4 py-2 z-[5555] bg-white">
                      <li className="hover:bg-hoverColor py-1 px-2 rounded-[4px]">
                        <NavLink to="/about">Get to know us</NavLink>
                      </li>
                      <li className="bg-[#DADADA] h-[1px]"></li>
                      <li className="hover:bg-hoverColor py-1 px-2 rounded-[4px]">
                        <NavLink to="/directors">Directors</NavLink>
                      </li>
                      <li className="bg-[#DADADA] h-[1px]"></li>
                      <li className="hover:bg-hoverColor py-1 px-2 rounded-[4px]">
                        <NavLink to="/core-team">Core Team</NavLink>
                      </li>
                      <li className="bg-[#DADADA] h-[1px]"></li>
                      <li className="hover:bg-hoverColor py-1 px-2 rounded-[4px]">
                        <NavLink to="/view-chapters">View Chapters</NavLink>
                      </li>
                      <li className="bg-[#DADADA] h-[1px]"></li>
                      <li className="hover:bg-hoverColor py-1 px-2 rounded-[4px]">
                        <NavLink to="/campus-leads">Campus Leads</NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <NavLink
                    className="hover:bg-hoverColor py-1 px-2"
                    to="/events"
                  >
                    Events
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="hover:bg-hoverColor py-1 px-2"
                    to="/gallery"
                  >
                    Gallery
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="hover:bg-hoverColor py-1 px-2"
                    to="/contact"
                  >
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </nav>
            <Link
              className="text-white bg-primaryColor hover:bg-hoverPrimary active:bg-activePrimary  py-2 px-4 rounded-[8px] "
              to="https://docs.google.com/forms/d/e/1FAIpQLScEgAnbByqmJJ-N0wud98xwte2QZLGFclLF9Ei0lRnrQYCBIg/viewform?pli=1"
            >
              Join Commmunity
            </Link>
          </div>
        )}

        {/* Desktop Navigation */}
        <div className="hidden lg:flex items-center gap-3 lg:gap-4">
          <nav>
            <ul className="flex items-center gap-3 lg:gap-4">
              <li>
                <NavLink className="hover:bg-hoverColor py-1 px-2" to="/">
                  Home
                </NavLink>
              </li>
              <li className="about-wrapper relative hover:bg-hoverColor">
                <p className="flex justify-center items-center gap-2 about hover:cursor-pointer py-1 px-2">
                  <span className="about-text">About ATC</span>
                  <img
                    src="/icons/arrow-up.svg"
                    alt=""
                    className="about-arrow"
                  />
                </p>
                <div className="drop-down absolute top-4 w-max -left-[10px]">
                  <ul className="flex flex-col gap-2 mt-8 shadow-[0_0_5px_-0px_rgba(0,0,0,0.3)] rounded-[8px] w-[170px] px-4 py-2 z-[5555] bg-white">
                    <li className="hover:bg-hoverColor py-1 px-2 rounded-[4px]">
                      <NavLink to="/about">Get to know us</NavLink>
                    </li>
                    <li className="bg-[#DADADA] h-[1px]"></li>
                    <li className="hover:bg-hoverColor py-1 px-2 rounded-[4px]">
                      <NavLink to="/directors">Directors</NavLink>
                    </li>
                    <li className="bg-[#DADADA] h-[1px]"></li>
                    <li className="hover:bg-hoverColor py-1 px-2 rounded-[4px]">
                      <NavLink to="/core-team">Core Team</NavLink>
                    </li>
                    <li className="bg-[#DADADA] h-[1px]"></li>
                    <li className="hover:bg-hoverColor py-1 px-2 rounded-[4px]">
                      <NavLink to="/view-chapters">View Chapters</NavLink>
                    </li>
                    <li className="bg-[#DADADA] h-[1px]"></li>
                    <li className="hover:bg-hoverColor py-1 px-2 rounded-[4px]">
                      <NavLink to="/campus-leads">Campus Leads</NavLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <NavLink className="hover:bg-hoverColor py-1 px-2" to="/events">
                  Events
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="hover:bg-hoverColor py-1 px-2"
                  to="/gallery"
                >
                  Gallery
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="hover:bg-hoverColor py-1 px-2"
                  to="/contact"
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </nav>
          <Link
            className="text-white bg-primaryColor hover:bg-hoverPrimary active:bg-activePrimary text-center  py-2 px-4 rounded-[8px] "
            to="https://docs.google.com/forms/d/e/1FAIpQLScEgAnbByqmJJ-N0wud98xwte2QZLGFclLF9Ei0lRnrQYCBIg/viewform?pli=1"
          >
            Join Commmunity
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
