import React from "react";
import { Helmet } from "react-helmet";
import Header from "../common/header";
import Footer from "../common/footer";

const PageLayout = ({ title, metaDescription, href, children }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <div>
        <Header />
        <main className="overflow-hidden">{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default PageLayout;
