import axios from "axios";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");

  const sendEmail = async (e) => {
    e.preventDefault();

    // Step 1: Save the contact to Brevo
    const contactData = {
      email: email,
      attributes: {
        FIRSTNAME: "", // You can add more attributes like FIRSTNAME, LASTNAME, etc.
      },
      listIds: [2], // Replace with the ID of the list you want to add the contact to
      updateEnabled: true, // If true, updates the contact if it already exists
    };

    try {
      const contactResponse = await axios.post(
        "https://api.brevo.com/v3/contacts",
        contactData,
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_BREVO_KEY,
          },
        }
      );

      if (contactResponse.status === 201) {
        // Step 2: Send a welcome email to the user
        const emailData = {
          sender: {
            email: "amazingtechcommunity@gmail.com",
            name: "ATC Africa",
          },
          to: [{ email: email }],
          subject: "Welcome to Our Newsletter!",
          htmlContent: `
            <h1>Welcome!</h1>
            <p>Thank you for signing up for our newsletter. We're glad to have you with us.</p>
            <p>If you wish to unsubscribe, click <a href="[UNSUBSCRIBE_LINK]">here</a>.</p>
          `,
        };

        const emailResponse = await axios.post(
          "https://api.brevo.com/v3/smtp/email",
          emailData,
          {
            headers: {
              "Content-Type": "application/json",
              "api-key": process.env.REACT_APP_BREVO_KEY,
            },
          }
        );

        if (emailResponse.status === 201) {
          setStatus(
            "You've been signed up successfully! A welcome email has been sent."
          );
        } else {
          setStatus("Signup successful, but failed to send the welcome email.");
        }
      } else {
        setStatus("Failed to sign up.");
      }
    } catch (error) {
      console.error(error);
      setStatus("An error occurred during signup.");
    }
  };
  return (
    <section className="footer bg-footer-gradient border-t-[1px] border-[#DADADA] py-[48px] px-6 lg:px-10">
      <div className="sm:hidden flex flex-col gap-[10px] w-full max-w-[400px] mb-14 mx-auto">
        <h3 className="font-[600] mb-[10px] text-center">
          SUBSCRIBE TO OUR NEWSLETTER
        </h3>
        <form className="flex gap-2 px-3 py-2 bg-white rounded-[8px] justify-center border-[1px] w-full">
          <div className="w-[60%]">
            <input
              type="email"
              className="block w-full h-full outline-none"
              required
              placeholder="Email Address"
            />
          </div>
          <div className="w-[40%]">
            <button className="text-white bg-primaryColor hover:bg-hoverPrimary active:bg-activePrimary  py-2 px-2 rounded-[8px] w-full">
              Subscribe
            </button>
          </div>
        </form>
      </div>
      <div className="flex flex-col gap-6 lg:grid grid-cols-[2fr_1fr_1fr_2fr] justify-between max-w-[1440px] lg:gap-4 mx-auto">
        <div className="w-full lg:max-w-[323px] flex flex-col gap-4">
          <img
            src="/icons/ATC_Africa_logo_(Purple).svg"
            alt="atc logo"
            className="block w-[162px] h-[64px] lg:relative lg:-top-6 lg:mx-auto"
          />
          <div className="flex flex-col gap-4">
            <p>
              ATC Africa is a powerhouse of change-makers, dreamers, and
              visionaries who are shaping the future of technology on this
              continent.
            </p>
            <button className="text-white bg-primaryColor hover:bg-hoverPrimary active:bg-activePrimary  py-2 px-4 rounded-[8px] w-max">
              <Link
                className="py-1 px-2"
                to="https://docs.google.com/forms/d/e/1FAIpQLScEgAnbByqmJJ-N0wud98xwte2QZLGFclLF9Ei0lRnrQYCBIg/viewform?pli=1"
              >
                Join Community
              </Link>
            </button>
          </div>
        </div>
        {/* Tablet Footer Links*/}
        <div className="hidden sm:grid grid-cols-[1fr_1fr_2fr] gap-8 lg:hidden">
          <div className="hidden sm:block lg:hidden">
            <h3 className="font-[600] mb-4">NAVIGATION</h3>
            <div className="flex flex-row gap-6 lg:flex-col lg:gap-[10px]">
              <div className="flex flex-col gap-[10px]">
                <NavLink className="w-max px-1 py-1" to="/">
                  Home
                </NavLink>
                <NavLink className="w-max px-1 py-1" to="/about">
                  About ATC
                </NavLink>
                <NavLink className="w-max px-1 py-1" to="/events">
                  Events
                </NavLink>
                <NavLink className="w-max px-1 py-1" to="/gallery">
                  Gallery
                </NavLink>
                <NavLink className="w-max px-1 py-1" to="/about">
                  Get to know us
                </NavLink>
                <NavLink className="w-max px-1 py-1" to="/directors">
                  Directors
                </NavLink>
                <NavLink className="w-max px-1 py-1" to="/core-team">
                  Core Team
                </NavLink>
                <NavLink className="w-max px-1 py-1" to="/view-chapters">
                  View Chapters
                </NavLink>
                <NavLink className="w-max px-1 py-1" to="/campus-leads">
                  Campus Leads
                </NavLink>
              </div>
            </div>
          </div>
          <div>
            <h3 className="font-[600] mb-4">OTHERS</h3>
            <div className="flex flex-row gap-6 lg:flex-col lg:gap-[10px]">
              <div className="flex flex-col gap-[10px] w-[121.79px] lg:w-full">
                <NavLink className="w-max px-1 py-1" to="/contact">
                  Contact Us
                </NavLink>
                <NavLink
                  className="w-max px-1 py-1"
                  to="https://flutterwave.com/donate/yisv4f3rmjwq"
                >
                  Donate
                </NavLink>
                <NavLink className="w-max px-1 py-1" to="/blog">
                  Blog
                </NavLink>
                <NavLink
                  className="w-max px-1 py-1"
                  to="https://docs.google.com/forms/d/e/1FAIpQLSeNosjPg5xR8W25b5JFQNXqa91cAfAWOgD2Iv_El9jd0uMGYg/viewform"
                >
                  Lead a Chapter
                </NavLink>
                <NavLink className="w-max px-1 py-1" to="/cookies-policy">
                  Cookies Policy
                </NavLink>
                <NavLink className="w-max px-1 py-1" to="/privacy-policy">
                  Privacy Policy
                </NavLink>
              </div>
            </div>
          </div>
          <div className=" hidden sm:flex flex-col gap-y-6 lg:gap-y-16 lg:hidden">
            <div>
              <h3 className="font-[600] mb-4">OPPORTUNITIES</h3>
              <div className="flex flex-col gap-[10px]">
                <NavLink className="w-max px-1 py-1" to="https://innovateU.com">
                  Apply for Mentorship
                </NavLink>
                <NavLink
                  className="w-max px-1 py-1"
                  to="https://docs.google.com/forms/u/0/d/e/1FAIpQLSdIaMhnNVsbvUmw_cOfFYYq0iUjx5V7MTWxNPMSqLLc66mc2Q/closedform"
                >
                  Tech Talent Fund Scholarship
                </NavLink>
              </div>
            </div>
            <div className="hidden sm:flex lg:hidden flex-col gap-[10px]">
              <h3 className="font-[600] mb-[10px]">
                SUBSCRIBE TO OUR NEWSLETTER
              </h3>
              <form
                className="flex gap-2 px-4 py-2 bg-white rounded-[8px] justify-center border-[1px] w-full max-w-[400px] lg:w-full"
                onSubmit={sendEmail}
              >
                <div className="w-[60%]">
                  <input
                    type="email"
                    className="block w-full h-full outline-none"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="Email Address"
                  />
                </div>
                <div className="w-[40%]">
                  <button
                    type="submit"
                    className="text-white bg-primaryColor hover:bg-hoverPrimary active:bg-activePrimary  py-2 px-2 rounded-[8px] w-full"
                  >
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
            <div className="flex flex-col gap-4">
              <h3 className="font-[600] mb-[10px]">CONNECT WITH US</h3>
              <div className="flex gap-4 items-center">
                <Link to="https://x.com/atcafricahq">
                  <img src="/icons/x.svg" alt="social media icons" />
                </Link>
                <Link to="https://www.linkedin.com/company/atcafricahq/?viewAsMember=true">
                  <img src="/icons/linkedin.svg" alt="social media icons" />
                </Link>
                <Link to="https://join.slack.com/t/amazingtechcommunity/shared_invite/zt-2j77senev-0otJjQ6e7VDHBSN1ccJJ5w">
                  <img src="/icons/slack.svg" alt="social media icons" />
                </Link>
                {/* <Link to="https://www.instagram.com/atcafricahq/"></Link> */}
                <Link to="https://web.facebook.com/atcafricahq">
                  <img src="/icons/facebook.svg" alt="social media icons" />
                </Link>
                <Link to="https://www.youtube.com/channel/UCgL5NPtVUIPRwLMMpEd71og">
                  <img src="/icons/youtube.svg" alt="social media icons" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* Desktop Footer Links */}
        <div className="sm:hidden lg:block">
          <h3 className="font-[600] mb-4">NAVIGATION</h3>
          <div className="flex flex-row gap-6 lg:flex-col lg:gap-[10px]">
            <div className="flex flex-col gap-[10px]">
              <NavLink className="w-max px-1 py-1" to="/">
                Home
              </NavLink>
              <NavLink className="w-max px-1 py-1" to="/about">
                About ATC
              </NavLink>
              <NavLink className="w-max px-1 py-1" to="/events">
                Events
              </NavLink>
              <NavLink className="w-max px-1 py-1" to="/gallery">
                Gallery
              </NavLink>
              <NavLink className="w-max px-1 py-1" to="/about">
                Get to know us
              </NavLink>
            </div>
            <div className="flex flex-col gap-[10px]">
              <NavLink className="w-max px-1 py-1" to="/directors">
                Directors
              </NavLink>
              <NavLink className="w-max px-1 py-1" to="/core-team">
                Core Team
              </NavLink>
              <NavLink className="w-max px-1 py-1" to="/view-chapters">
                View Chapters
              </NavLink>
              <NavLink className="w-max px-1 py-1" to="/campus-leads">
                Campus Leads
              </NavLink>
            </div>
          </div>
        </div>
        <div className="sm:hidden lg:block">
          <h3 className="font-[600] mb-4">OTHERS</h3>
          <div className="flex flex-row gap-6 lg:flex-col lg:gap-[10px]">
            <div className="flex flex-col gap-[10px] w-[121.79px] lg:w-full">
              <NavLink className="w-max px-1 py-1" to="/contact">
                Contact Us
              </NavLink>
              <NavLink
                className="w-max px-1 py-1"
                to="https://flutterwave.com/donate/yisv4f3rmjwq"
              >
                Donate
              </NavLink>
              <NavLink className="w-max px-1 py-1" to="/blog">
                Blog
              </NavLink>
            </div>
            <div className="flex flex-col gap-[10px]">
              <NavLink
                className="w-max px-1 py-1"
                to="https://docs.google.com/forms/d/e/1FAIpQLSeNosjPg5xR8W25b5JFQNXqa91cAfAWOgD2Iv_El9jd0uMGYg/viewform"
              >
                Lead a Chapter
              </NavLink>
              <NavLink className="w-max px-1 py-1" to="/cookies-policy">
                Cookies Policy
              </NavLink>
              <NavLink className="w-max px-1 py-1" to="/privacy-policy">
                Privacy Policy
              </NavLink>
            </div>
          </div>
        </div>
        <div className="flex sm:hidden lg:flex flex-col gap-y-6 lg:gap-y-16">
          <div>
            <h3 className="font-[600] mb-4">OPPORTUNITIES</h3>
            <div className="flex flex-col gap-[10px]">
              <NavLink className="w-max px-1 py-1" to="https://innovateU.com">
                Apply for Mentorship
              </NavLink>
              <NavLink
                className="w-max px-1 py-1"
                to="https://docs.google.com/forms/u/0/d/e/1FAIpQLSdIaMhnNVsbvUmw_cOfFYYq0iUjx5V7MTWxNPMSqLLc66mc2Q/closedform"
              >
                Tech Talent Fund Scholarship
              </NavLink>
            </div>
          </div>
          <div className="hidden lg:flex flex-col gap-[10px]">
            <h3 className="font-[600] mb-[10px]">
              SUBSCRIBE TO OUR NEWSLETTER
            </h3>
            <form
              className="flex gap-2 px-4 py-2 bg-white rounded-[8px] justify-center border-[1px] w-full max-w-[400px] lg:w-full"
              onSubmit={sendEmail}
            >
              <div className="w-[65%]">
                <input
                  type="email"
                  className="block w-full h-full outline-none"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="Email Address"
                />
              </div>
              <div className="w-[35%]">
                <button
                  type="submit"
                  className="text-white bg-primaryColor hover:bg-hoverPrimary active:bg-activePrimary  py-2 px-2 rounded-[8px] w-full"
                >
                  Subscribe
                </button>
              </div>
            </form>
          </div>
          <div className="flex flex-col gap-4">
            <h3 className="font-[600] mb-[10px]">CONNECT WITH US</h3>
            <div className="flex gap-4 items-center">
              <Link to="https://x.com/atcafricahq">
                <img src="/icons/x.svg" alt="social media icons" />
              </Link>
              <Link to="https://www.linkedin.com/company/atcafricahq/?viewAsMember=true">
                <img src="/icons/linkedin.svg" alt="social media icons" />
              </Link>
              <Link to="https://join.slack.com/t/amazingtechcommunity/shared_invite/zt-2j77senev-0otJjQ6e7VDHBSN1ccJJ5w">
                <img src="/icons/slack.svg" alt="social media icons" />
              </Link>
              {/* <Link to="https://www.instagram.com/atcafricahq/"></Link> */}
              <Link to="https://web.facebook.com/atcafricahq">
                <img src="/icons/facebook.svg" alt="social media icons" />
              </Link>
              <Link to="https://www.youtube.com/channel/UCgL5NPtVUIPRwLMMpEd71og">
                <img src="/icons/youtube.svg" alt="social media icons" />
              </Link>
            </div>
          </div>
        </div>
        {/* Desktop Footer Links */}
      </div>
      <p className="text-[#A0A0A0] py-[16px] border-t-[1px] border-[#DADADA] mt-16 text-center font-[400]">
        Copyright© Amazing Tech Community Africa {new Date().getFullYear()}. All
        rights reserved®
      </p>
    </section>
  );
};

export default Footer;
