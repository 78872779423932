import React, { useEffect, useState } from "react";
import PageLayout from "../components/layout/pageLayout";
import { useParams } from "react-router-dom";

const BlogPage = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    const getBlog = async () => {
      try {
        setIsLoading(true);
        const req = await fetch("/data/blogposts.json");
        const res = await req.json();
        const filterRes = res.filter((data) => data.address === id);
        if (filterRes[0].name) setData(filterRes);
        setIsLoading(false);
        console.log(filterRes);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getBlog();
  }, []);
  return (
    <PageLayout
      title={data && `${data[0].name} - ATC Africa`}
      metaDescription={data && `ATC Africa: ${data[0].name}`}
      href="https://atcafrica.com.ng"
    >
      <section className="my-8 w-full max-w-[900px] px-6 lg:px-10 mx-auto">
        {loading ? (
          <div className="h-[300px] flex flex-col items-center justify-center">
            <p className="text-[20px] text-gray-700">Loading...</p>
          </div>
        ) : data ? (
          <div>
            <div>
              <h2 className="text-[24px] md:text-[24px] text-primaryColor font-[600]">
                {data[0].name}
              </h2>
              <div className="mt-2 flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <img
                    src={data[0].author}
                    alt="author-pic"
                    className="size-[40px]"
                  />
                  <div>
                    <p className="text-[16px]">{data[0].authorName}</p>
                    <p className="text-[14px] text-[#808080]">{data[0].date}</p>
                  </div>
                </div>
                <p className="text-[14px] text-[#808080]">{data[0].readTime}</p>
              </div>
            </div>

            <div className="mt-6 mb-24">
              <iframe
                src={data[0].youtubeVideo}
                title={data[0].name}
                className="border-[1px] h-[250px] md:h-[350px] lg:h-[450px] w-full mx-auto"
              >
                Enable Javascript to watch this video
              </iframe>
              {data[0].text && (
                <>
                  <p className="mt-6 leading-8">
                    On the 3rd of May, 2024, Today, we embarked on an exclusive
                    tour of a truly remarkable place in Nigeria, @MicrosoftADC a
                    hub for learning and growth!" where we were able to gain
                    insights into Microsoft's operations in Nigeria.
                    <br />
                    <br />
                    Where we learn about Microsoft's focus areas in the Nigerian
                    market, their solutions for local businesses, and their
                    approach to digital transformation in the region where we
                    lead to valuable connections and potential business
                    opportunities.
                    <br />
                    <br />
                    Gain a deeper understanding of the Nigerian tech landscape:
                    By visiting Microsoft Nigeria, We were able to get firsthand
                    look at how a major tech company is operating in the
                    country. Microsoft is at the forefront of technological
                    advancements.
                    <br />
                    The tour expose our members to new ideas and upcoming
                    technologies.
                  </p>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="h-[300px] flex flex-col items-center justify-center">
            <p className="text-[20px] text-gray-700">Post not found..!</p>
          </div>
        )}
      </section>
    </PageLayout>
  );
};

export default BlogPage;
