import React from "react";
import { Link } from "react-router-dom";

const BlogCard = ({
  cardImage,
  type,
  text,
  author,
  authorName,
  date,
  typeColor,
  bgTypeColor,
  readTime,
  blogAddress,
}) => {
  return (
    <Link
      to={blogAddress}
      className="flex flex-col justify-between w-full max-w-[336px] h-[400px] rounded-[12px] shadow-[0px_9px_20px_0px_#00000029]"
    >
      <img
        src={cardImage}
        alt="blog-pic"
        className="w-[100%] h-[190px] object-cover rounded-t-[12px]"
      />
      <div className="flex-grow px-4 py-4 flex justify-between flex-col gap-4">
        <p
          className={`text-[12px] ${typeColor} ${bgTypeColor} font-[500] w-max px-[12px] py-[2px] rounded-[4px]`}
        >
          {type}
        </p>
        <p className="flex-grow lg:text-[18px] font-[600] text-primaryColor">
          {text}
        </p>

        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <img src={author} alt="author-pic" className="size-[40px]" />
            <div>
              <p className="text-[14px]">{authorName}</p>
              <p className="text-[12px] text-[#808080]">{date}</p>
            </div>
          </div>
          <p className="text-[12px] text-[#808080]">{readTime}</p>
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;
