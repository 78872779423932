import React from "react";

const ProgramCard = ({ image, title, text }) => {
  return (
    <div
      className="flex flex-col px-4 py-[10px] lg:py-[18px] rounded-[16px] items-start w-full max-w-[332px] h-[390px] lg:h-[432px] shadow-[0px_9px_20px_0px_#00000029] gap-3 lg:gap-8
"
    >
      <img src={image} alt="program-pic" className="block mx-auto" />
      <div>
        <p className="text-[1rem] md:text-[18px] lg:text-[24px] text-primaryColor font-[600]">
          {title}
        </p>
        <p className="mt-1 lg:mt-2 lg:leading-[24px] text-[14px] lg:text-4">
          {text}
        </p>
      </div>
    </div>
  );
};

export default ProgramCard;
