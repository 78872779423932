import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
import MissionCard from "../components/card/missionCard";
import ProgramCard from "../components/card/programCard";
import EventCard from "../components/card/eventCard";
import TestimonialCard from "../components/card/testimonialCard";
import BlogCard from "../components/card/blogCard";
import PageLayout from "../components/layout/pageLayout";

const Home = () => {
  const scrollRef = useRef(null);
  const handleRightScroll = () => {
    scrollRef.current.scrollBy({
      top: 0,
      left: 300,
      behavior: "smooth",
    });
  };
  const handleLeftScroll = () => {
    scrollRef.current.scrollBy({
      top: 0,
      left: -300,
      behavior: "smooth",
    });
  };
  return (
    <PageLayout
      title="Home - ATC Africa"
      metaDescription="Welcome to the official ATC Africa Website"
      href="https://atcafrica.com.ng"
    >
      <section>
        <div className="bg-hero-pattern bg-[center_top_-5rem] px-6 lg:px-10 pt-14 pb-6 md:pt-20 bg-no-repeat -z-[888]">
          <div className="w-full max-w-[545px] text-center mx-auto flex flex-col gap-3 md:gap-5 justify-center items-center">
            <motion.h1
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2 }}
              className="text-[32px] mb-4 md:text-[48px] font-[600] relative md:leading-[64px]"
            >
              Building a Better Future{" "}
              <span>
                for Africans in{" "}
                <span className="relative text-primaryColor inline-block">
                  TECH
                  <img src="/icons/yellow.svg" alt="" className="absolute" />
                </span>
              </span>
            </motion.h1>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2, delay: 2 }}
              className="text-[14px] md:text-[20px] md:leading-[32px]"
            >
              A powerhouse of change-makers who are shaping the future of
              technology ecosystem in Africa.
            </motion.p>
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2, delay: 2 }}
              className="w-max text-white bg-primaryColor hover:bg-hoverPrimary active:bg-activePrimary py-2 px-4 rounded-[8px]"
            >
              <Link
                className="py-1 px-2"
                to="https://docs.google.com/forms/d/e/1FAIpQLScEgAnbByqmJJ-N0wud98xwte2QZLGFclLF9Ei0lRnrQYCBIg/viewform?pli=1"
              >
                Join Commmunity
              </Link>
            </motion.button>
          </div>
          <div className="mt-24 border-b-[1px] border-primaryColor -mx-6 md:mx-0">
            <img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2, delay: 2 }}
              src="/images/hero-image.png"
              alt="hero"
              className="block w-full min-h-[300px] lg:h-full max-w-[1440px] mx-auto object-cover"
            />
          </div>
        </div>
        <Marquee
          className="mx-auto max-w-[1440px] px-14 -z-[888]"
          autoFill={true}
        >
          <div className="flex justify-between items-center gap-x-16 gap-y-8 px-16">
            <img
              src="/icons/Cardify.svg"
              alt=""
              className="w-[170px] h-[30px] md:h-[40px]  grayscale"
            />
            <img
              src="/icons/marquee-star.svg"
              alt=""
              className="w-[30px] md:w-[40px]"
            />
            <img
              src="/icons/ISC2.svg"
              alt=""
              className="w-[100px] h-[38px] md:h-[48px]  grayscale"
            />
            <img
              src="/icons/marquee-star.svg"
              alt=""
              className="w-[30px] md:w-[40px]"
            />
            <img
              src="/icons/Tensorflow.svg"
              alt=""
              className="w-[100px] h-[50px] md:h-[60px] grayscale"
            />
            <img
              src="/icons/marquee-star.svg"
              alt=""
              className="w-[30px] md:w-[40px]"
            />
            <img
              src="/icons/1App.svg"
              alt=""
              className="w-[100px] h-[50px] md:h-[60px]  grayscale"
            />
            <img
              src="/icons/marquee-star.svg"
              alt=""
              className="w-[30px] md:w-[40px]"
            />
          </div>
        </Marquee>
      </section>
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2.5 }}
        className="mx-auto my-8 md:my-16 px-6 lg:px-10"
      >
        <div className="w-full max-w-[1440px] mx-auto">
          <h2 className="uppercase md:text-[24px] font-[600] text-center py-2 px-2 mx-auto border-b-[4px] border-primaryColor w-max">
            Our Mission
          </h2>
          <div className="w-full grid grid-cols-[1fr] lg:grid-cols-[1fr_1fr] gap-x-16 gap-y-4 lg:items-start my-16 ">
            <div className="w-full h-max">
              <div className="flex flex-col w-full max-w-[700px] mx-auto lg:max-w-full gap-8 lg:gap-20 xl:gap-28 relative">
                <img
                  src="icons/mission-line.svg"
                  alt=""
                  className="absolute top-4 -z-[8] sm:top-8 left-[15px] lg:top-16 lg:left-[23px] w-[3px] h-[63.5%]"
                />
                <div className="flex items-start">
                  <img
                    src="/icons/mission-list-icon.svg"
                    alt=""
                    className="size-[32px] lg:size-[50px] bg-white"
                  />
                  <p className="lg:text-[24px] px-4 lg:px-8 leading-[34px]">
                    To be a{" "}
                    <span className="text-primaryColor">
                      community of change-makers
                    </span>{" "}
                    who will partake in shaping the future of technology.
                  </p>
                </div>
                <div className="flex items-start">
                  <img
                    src="/icons/mission-list-icon.svg"
                    alt=""
                    className="size-[32px] lg:size-[50px] bg-white"
                  />
                  <p className="lg:text-[24px] px-4 lg:px-8 leading-[34px]">
                    Our goal is to{" "}
                    <span className="text-primaryColor">
                      build a quality and comprehensive community
                    </span>{" "}
                    for technology infrastructure.
                  </p>
                </div>
                <div className="flex items-start">
                  <img
                    src="/icons/mission-list-icon.svg"
                    alt=""
                    className="size-[32px] lg:size-[50px] bg-white"
                  />
                  <p className="lg:text-[24px] px-4 lg:px-8 leading-[34px]">
                    To be a community that establishes and maintain an{" "}
                    <span className="text-primaryColor">
                      effective operational environment for all individuals with
                      various expertise in the community.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="px-4 mx-auto">
              <img src="/images/mission-pic.png" alt="" />
            </div>
          </div>
          <div className="w-full px-2 lg:px-20 mx-auto flex flex-wrap items-center justify-center lg:justify-between gap-8 md:gap-10 lg:gap-16">
            <MissionCard
              image="/icons/user-group.svg"
              number="1,000"
              text="Community members"
            />
            <MissionCard
              image="/icons/user-group.svg"
              number="11"
              text="Partners & Sponsors"
            />
            <MissionCard
              image="/icons/user-group.svg"
              number="25"
              show={false}
              text="Campuses"
            />
            <MissionCard
              image="/icons/user-group.svg"
              number="10"
              text="Programs"
            />
            <MissionCard
              image="/icons/user-group.svg"
              number="1,000"
              text="Followers"
            />
          </div>
        </div>
      </motion.section>
      <section className="relative px-6 lg:px-10 w-full max-w-[1440px] mx-auto">
        <img
          src="/images/wave-bg.svg"
          alt="wave-bg"
          className="-z-[888] lg:flex absolute lg:-top-44 w-full pointer-events-none"
        />
        <div className="w-full max-w-[1440px] mx-auto">
          <h2 className="uppercase md:text-[24px] font-[600] text-center py-2 px-2 mx-auto border-b-[4px] border-primaryColor w-max">
            Our programs
          </h2>
          <p className="text-center my-4 leading-[26px] md:px-[20px]">
            We are dedicated to creating a better future for Africas tech
            community. We provide a platform where techies can explore, connect,
            and collaborate with the best minds in the field. Get to know our
            Programs;
          </p>
          <div className="flex flex-wrap justify-center items-center gap-8 md:gap-10 lg:gap-16 my-8">
            <ProgramCard
              image="/images/iconoir_learning.svg"
              title="HackHive"
              text="Join our yearly hackathon-style event where participants collaborate on building projects based on a specific theme. With mentoring and demo sessions, HackHive is the perfect opportunity to showcase your skills, learn from experts, and network with like-minded individuals."
            />
            <ProgramCard
              image="/images/mdi_women.svg"
              title="TechTalk Treks"
              text="Join us for a series of tech talks hosted at unique venues. These monthly events encourage knowledge sharing and networking in inspiring settings. Stay informed about the latest tech advancements and connect with industry leaders.
"
            />
            <ProgramCard
              image="/images/flat-color-icons_podium-with-speaker.svg"
              title="Women in Tech Wave"
              text="We believe in promoting diversity and inclusion in tech. Women in Tech Wave is an empowering event featuring talks by women in tech, career development workshops, and valuable networking opportunities. Join us to be part of the movement.
"
            />
          </div>
        </div>
      </section>
      <section className="px-6 lg:px-10 my-16">
        <div className="w-full max-w-[1440px] mx-auto">
          <h2 className="uppercase md:text-[24px] font-[600] text-center py-2 px-2 mx-auto border-b-[4px] border-primaryColor w-max">
            Our Campus Events
          </h2>
          <p className="text-center my-4 leading-[26px] md:px-[20px]">
            Stay ahead of the tech curve with a sneak peek into our upcoming
            series of events. Get ready to savor every thrilling moment!
          </p>
          <div className="flex flex-wrap justify-center items-center gap-8 md:gap-10 lg:gap-16 my-8">
            <EventCard
              image="/images/pixels-photo.png"
              title="Creativity Unleashed"
              text="Join us for an event centered around user experience and design, focusing on usability, user research, and creating captivating digital interfaces."
            />
            <EventCard
              image="/images/open-source.png"
              title="Open Source Meetup"
              text="Celebrate the power of open-source technologies at our community-driven meetup. Engage in discussions about open-source contributions and collaboration."
            />
            <EventCard
              image="/images/security.png"
              title="Cyber-Meetup"
              text="Attend our cybersecurity meetup and gain insights into the latest threats, defensive strategies, and ethical hacking techniques."
            />
            <EventCard
              image="/images/databytes.png"
              title="DataBytes"
              text="Join our data-focused meetup to explore the latest trends and practices in data science, machine learning, and analytics and Paper Presentation."
            />
            <EventCard
              image="/images/ar-vr.png"
              title="AR/VR Fusion"
              text="Attend our immersive technology event for a hands-on experience with augmented reality and virtual reality technologies and workshops highlighting AR/VR projects. "
            />
            <EventCard
              image="/images/meetup.png"
              title="WriteTech Meetup"
              text="Connect with other tech writers and content creators at our casual meetup. Network, exchange ideas, and foster professional connections within the industry."
            />
          </div>
        </div>
      </section>
      <section className="px-4 lg:px-10 my-16">
        <div className="w-full max-w-[1440px] mx-auto">
          <h2 className="uppercase md:text-[24px] font-[600] text-center py-2 px-2 mx-auto border-b-[4px] border-primaryColor w-max">
            Testimonials
          </h2>
          <p className="text-center my-4 leading-[26px]">
            Hear directly from our happy community members
          </p>
          <div className="">
            <div className="flex items-center">
              <img
                src="/icons/arrow-left.svg"
                alt="arrow-left"
                className="hidden md:block size-[40px] md:size-[60px] cursor-pointer"
                onClick={handleLeftScroll}
              />
              <div
                className=" flex overflow-auto no-scrollbar gap-6 px-2 py-4"
                ref={scrollRef}
              >
                <div className="">
                  <TestimonialCard
                    text="I have benefited alots, most especially connecting with great minds who are very passionate about self development and career growth and I can say this, ATC Africa is a great place to connect with minds that would boost your professional life."
                    name="Abdulkareem Aisha Ajike"
                    role="Community member"
                    image="/icons/abdulkareem.png"
                  />
                </div>
                <div className="">
                  <TestimonialCard
                    text="I gained a deeper understanding of the different avenues within the tech field during the first Scholarship given to me by ATC Africa in SQI College of ICT. Hearing from professionals about their journeys and challenges they overcame provided me with a perspective on what to expect and how to navigate my own path."
                    name="Ayanyiola Enoch Goodness"
                    role="Community member"
                    image="/images/enoch.png"
                  />
                </div>
                <div className="">
                  <TestimonialCard
                    text="I was one the beneficiaries of the tech scholarship program organized by ATC Africa to learn Product Design in SQI, Iwo road. I successfully completed the 1st phase of the training after two months. I want to say a big thank you to ATC Africa for that great opportunity."
                    name="Victoria Christopher Ekara"
                    role="Community member"
                    image="/images/victoria.png"
                  />
                </div>
                <div className="">
                  <TestimonialCard
                    text="Join our yearly hackathon-style event where participants collaborate on building projects based on a specific theme. With mentoring and demo sessions, ATC Africa is the perfect opportunity to showcase your skills, learn from experts, and network with like-minded individuals."
                    name="Ade Clinton"
                    role="Community Manager"
                    image="/images/judah.png"
                  />
                </div>
              </div>
              <img
                src="/icons/arrow-right.svg"
                alt="arrow-left"
                className="hidden md:block size-[40px] md:size-[60px] cursor-pointer"
                onClick={handleRightScroll}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="px-6 lg:px-10 my-8  w-full max-w-[1440px] mx-auto">
          <h2 className="uppercase md:text-[24px] font-[600] text-center py-2 px-2 mx-auto border-b-[4px] border-primaryColor w-max">
            shots from previous events
          </h2>
          <p className="text-center my-4 leading-[26px]">
            Experience the highlights from our incredible gathering!
          </p>
          <Marquee
            className="w-full px-14 -z-[888]"
            autoFill={true}
            speed={150}
          >
            <div className="w-full overflow-x-auto no-scrollbar">
              <div className="w-full flex h-[302px] lg:h-[606px] gap-[2px] lg:gap-[6px] mr-[2px] lg:mr-[6px]">
                <div className="flex gap-[2px] lg:gap-[6px]">
                  <div className="w-[202px] lg:w-[506px] h-full flex flex-col gap-[2px] lg:gap-[6px]">
                    <div className="w-[202px] lg:w-[506px]">
                      <img
                        src="/images/1.png"
                        alt=""
                        className="w-full h-[150px] lg:h-[300px] border-primaryColor border-[2px] object-cover"
                      />
                    </div>
                    <div className="h-[150px] lg:h-[300px] flex justify-between">
                      <img
                        src="/images/2.png"
                        alt=""
                        className="w-[100px] lg:w-[250px] h-full border-primaryColor border-[2px] object-cover object-left"
                      />
                      <img
                        src="/images/3.png"
                        alt=""
                        className="w-[100px] lg:w-[250px] border-primaryColor border-[2px] object-cover"
                      />
                    </div>
                  </div>
                  <div className="w-[125px] lg:w-[350px]">
                    <img
                      src="/images/4.png"
                      alt=""
                      className="w-full h-full border-primaryColor border-[2px] object-cover"
                    />
                  </div>
                </div>
                <div className="flex gap-[2px] lg:gap-[6px]">
                  <div className="w-[202px] lg:w-[506px] h-full flex flex-col gap-[2px] lg:gap-[6px]">
                    <div className="w-[202px] lg:w-[506px]">
                      <img
                        src="/images/5.png"
                        alt=""
                        className="w-full h-[150px] lg:h-[300px] border-primaryColor border-[2px] object-cover"
                      />
                    </div>
                    <div className="h-[150px] lg:h-[300px] flex justify-between">
                      <img
                        src="/images/6.png"
                        alt=""
                        className="w-[100px] lg:w-[250px] h-full border-primaryColor border-[2px] object-cover object-left"
                      />
                      <img
                        src="/images/7.png"
                        alt=""
                        className="w-[100px] lg:w-[250px] border-primaryColor border-[2px] object-cover"
                      />
                    </div>
                  </div>
                  <div className="w-[125px] lg:w-[350px]">
                    <img
                      src="/images/8.png"
                      alt=""
                      className="w-full h-full border-primaryColor border-[2px] object-cover"
                    />
                  </div>
                </div>
                <div className="flex gap-[2px] lg:gap-[6px]">
                  <div className="w-[202px] lg:w-[506px] h-full flex flex-col gap-[2px] lg:gap-[6px]">
                    <div className="w-[202px] lg:w-[506px]">
                      <img
                        src="/images/9.png"
                        alt=""
                        className="w-full h-[150px] lg:h-[300px] border-primaryColor border-[2px] object-cover"
                      />
                    </div>
                    <div className="h-[150px] lg:h-[300px] flex justify-between">
                      <img
                        src="/images/10.png"
                        alt=""
                        className="w-[100px] lg:w-[250px] h-full border-primaryColor border-[2px] object-cover object-left"
                      />
                      <img
                        src="/images/11.png"
                        alt=""
                        className="w-[100px] lg:w-[250px] border-primaryColor border-[2px] object-cover object-left"
                      />
                    </div>
                  </div>
                  <div className="w-[125px] lg:w-[350px]">
                    <img
                      src="/images/12.png"
                      alt=""
                      className="w-full h-full border-primaryColor border-[2px] object-cover"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Marquee>
          <Link className="my-4 text-center p-4 rounded-[8px] block w-full max-w-[522px] mx-auto bg-[#F5EEFF] hover:bg-[#DAC3FF]">
            View all Albums
          </Link>
        </div>

        <div className="flex flex-col justify-center items-center bg-support-bg bg-[center_top_-5rem] bg-no-repeat bg-gray-600 bg-blend-multiply bg-cover px-6 py-8 lg:py-[60px] my-16 ">
          <div className="w-full max-w-[1120px]">
            <p className="text-center text-[16px] md:text-[22px] lg:text-[28px] font-[600] text-white">
              ATC Africa firmly desire to see young ones rise and achieve their
              dreams. Empower our mission in shaping the future of tech in
              Africa. Support our tech community to foster innovation, connect
              with like-minded professionals, and contribute to the advancement
              of technology in Africa.
            </p>
          </div>
          <Link
            to="https://flutterwave.com/donate/yisv4f3rmjwq"
            className="shadow-[0p_9px_20px_0px_#00000029] my-4 bg-white py-[12px] md:py-4 px-[60px] md:px-[120px] text-[1rem] rounded-[8px] text-primaryColor"
          >
            Support Us Today
          </Link>
        </div>
      </section>
      <section className="px-6 lg:px-10 my-16 w-full max-w-[1440px] mx-auto">
        <div className="">
          <h2 className="uppercase md:text-[24px] font-[600] text-center px-2 py-2  mx-auto border-b-[4px] border-primaryColor w-max">
            our admirable partners
          </h2>
          <div className="flex flex-wrap justify-center items-center gap-x-8 gap-y-8 mt-8">
            <img
              src="/icons/Cardify.svg"
              alt=""
              className="w-[150px] lg:w-[202px]"
            />
            <img
              src="/icons/ISC2.svg"
              alt=""
              className="w-[71px] lg:w-[121px]"
            />
            <img
              src="/icons/Tensorflow.svg"
              alt=""
              className="w-[100px] lg:w-[150px]"
            />
            <img
              src="/icons/1App.svg"
              alt=""
              className="w-[100px] lg:w-[150px]"
            />
            <img
              src="/icons/zeeh.png"
              alt=""
              className="w-[100px] lg:w-[150px]"
            />
            <img
              src="/icons/Paywave.png"
              alt=""
              className="w-[100px] lg:w-[150px]"
            />
          </div>
        </div>
      </section>
      <section className="px-6 lg:px-10 my-16 w-full max-w-[1440px] mx-auto">
        <h2 className="uppercase md:text-[24px] font-[600] text-center py-2 px-2 mx-auto border-b-[4px] border-primaryColor w-max">
          Atc blog
        </h2>
        <p className="text-center my-4 leading-[26px]">
          Discover the latest news happening in our community and in the tech
          world
        </p>
        <div className="flex flex-wrap justify-center gap-8 md:gap-10 lg:gap-16 my-8">
          <BlogCard
            blogAddress="/blog/tour-to-microsoft-nigeria"
            cardImage="/images/blog2.jpg"
            type="ATC HQTZ"
            text="Our Tour to Microsoft Nigeria to Spark Creativity and Innovation."
            author="/icons/ATC.svg"
            authorName="ATC Africa"
            date="12th May,2024"
            readTime="3 mins read"
            bgTypeColor="bg-[#E4FFE4]"
            typeColor="text-[#008000]"
          />
          <BlogCard
            blogAddress="/blog/tour-to-vibranium-valley"
            cardImage="/images/blog1.jpg"
            type="ATC HQTZ"
            text="Our Tour to Vibranium Valley to Spark Creativity and Innovation."
            author="/icons/ATC.svg"
            authorName="ATC Africa"
            date="8th March,2024"
            readTime="5 mins read"
            bgTypeColor="bg-[#FFE4E4]"
            typeColor="text-[#FF0000]"
          />
          <BlogCard
            blogAddress="/blog/cybsecurity-movement-in-ten-states"
            cardImage="/images/blog3.jpg"
            type="Cybersecurity Movement in 10 states"
            text="Cybersecurity Movement in 10 states: Lagos, Kaduna, Kebbi, Ibadan and others."
            author="/icons/ATC.svg"
            authorName="ATC Africa"
            date="12th March,2024"
            readTime="3 mins read"
            bgTypeColor="bg-[#E4FFE4]"
            typeColor="text-[#008000]"
          />
        </div>
        <Link className="my-4 text-center p-4 rounded-[8px] w-[90%] max-w-[522px] block mx-auto bg-[#F5EEFF] hover:bg-[#DAC3FF]">
          See more
        </Link>
      </section>
      <section className="flex flex-col justify-center items-center my-16 bg-join bg-cover bg-no-repeat h-[416px]">
        <div className="w-full px-[20px] lg:px-[80px] h-[266px] bg-white flex flex-col justify-center items-center">
          <div className="w-full max-w-[1440px] flex flex-col justify-center gap-4 px-[20px] py-[32px] lg:px-[80px]">
            <p className="text-[1rem] lg:text-[26px] text-center text-primaryColor">
              Join us on a journey where innovation knows no bounds, and
              possibilities are limitless. <br />
              Join us today!
            </p>
            <button className="text-white bg-primaryColor hover:bg-hoverPrimary active:bg-activePrimary  py-2 px-4 rounded-[8px] w-max mx-auto">
              <Link
                className="py-1 px-2"
                to="https://docs.google.com/forms/d/e/1FAIpQLScEgAnbByqmJJ-N0wud98xwte2QZLGFclLF9Ei0lRnrQYCBIg/viewform?pli=1"
              >
                Join Commmunity
              </Link>
            </button>
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

export default Home;
